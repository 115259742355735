@tailwind base;
@tailwind components;
@tailwind utilities;


body{
  max-width: 100%;
  overflow-x: hidden;
}


/* styles.css */
.light-theme {
  background-color: #ffffff;
  color: #000000;
}

.dark-theme {
  background-color: #0D0D0D;
  color: #ffffff;
}
 button:disabled {
    opacity: 0.6;
  }



table {
  border-collapse: collapse;
  width: 100%;
  min-width: 950px;

  tr th {
    padding: 8px 0;
    font-size: 24px;
    font-weight: 400;
  }
  tr td {
    padding: 8px 0;
    font-size: 20px;
    font-weight: 300;
  }
}


@media (max-width: 1020px) {
  table {
    tr th {
      padding: 8px 0;
      font-size: 16px;
      font-weight: 400;
    }
    tr td {
      padding: 5px 0;
      font-size: 14px;  
      font-weight: 300;
    }
  }
}

@media (max-width: 1020px) {
  .desktop-view {
    display: none !important;
  }
  .mobile-view {
    display: block !important;
    width: 100%;
  }
}

@media (min-width: 1021px) {
  .desktop-view {
    display: block !important;
  }
  .mobile-view {
    display: none !important;
  }
}


// Tabs 
.dark-theme {
  .tabs-sec{
    // color: #000;
  }
  
  .tabs-content{
    // .rounded-xl.bg-white.p-3{
    //   background-color: #000000;
    // }
  }

}