#right-drawer{
  
    .MuiPaper-root {
    // max-width: 100% !important;
    width: 230px !important;
    height: 100vh !important;
    // transition: transform 0ms cubic-bezier(0, 0, 0.2, 1) 0ms !important;
  }
  .MuiBackdrop-root{
    background-color: transparent !important;
  }
}
