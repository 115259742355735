.hero-sec {
  background-image: url("../../images/bg/bg-dashboard.png");
}

.tw-connect-wallet {
  background-color: #595959 !important;
  border-radius: 12px !important;
  height: 41px !important;
  color: #fff !important;
  padding: 5px 12px!important;
}

.connect-wallet {
  padding: 5px 12px!important;
}
.menulist {
  a {
    font-size: 20px;
  }
  .active {
    background-color: #6175e8;
    color: #fff;
    border-radius: 12px;
    padding: 6px;
  }
}

// Mobile
.drawer-content {
  .MuiPaper-elevation {
    width: 230px;
    // padding: 0 15px;
  }

  .MuiList-padding {
    a {
      display: block;
      font-size: 16px;
      border-bottom: 1px solid #80808047;
      padding: 5px;
    }
    .active {
      background-color: #6175e8;
      color: #fff;
    }
  }
}




.light-theme{
  .light-social{
    display: none;
  }
  .dark-social{
    display: flex;
  }
}

.dark-theme{
  .dashboard-card-header{
    .leftside{
      background-color: #0D0D0D;
    }
  }

  .header-sec{
    background-color: #0D0D0D;
  }
  .dark-social{
    display: none;
  }
  .light-social{
    display: flex;
  }
}